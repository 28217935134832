.modal {
    position: absolute; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: #daf6ff; /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  
  }